<template>
	<v-container>
		<v-row>
			<v-col>
				<admin-default-subheader>Saved Reports</admin-default-subheader>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<ReportRowData
					v-for="certificate in displayedCertificates"
					:key="certificate.id"
					:certificate="certificate"
					:dateType="'created'"
					:displayPopup="displayPopup"
				>
					<template #buttons>
						<button
							v-for="(btn, index) in buttons"
							class="report-list__button primary-text"
							:key="index"
							@click="btn.action(certificate)"
						>
							{{ btn.title }}
						</button>
					</template>
				</ReportRowData>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import ReportRowData from "./components/ReportRowData.vue";

export default {
	name: "CertificatesSaved",
	props: ["displayedCertificates"],
	components: { AdminDefaultSubheader, ReportRowData },
	data() {
		return {
			buttons: [
				{
					title: "Delete",
					action: () => {
						console.log("click");
					}
				},
				{
					title: "Download PDF",
					action: () => {
						console.log("click");
					}
				}
			],
			displayPopup: false
		};
	}
};
</script>

<style></style>
